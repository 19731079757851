/* @import url("https://fonts.googleapis.com/css2?family=Lora&family=Noto+Serif+TC:wght@300;400;500;700&display=swap"); */
.text-white {
  color: #fff;
}

h1 {
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  text-transform: capitalize;
  color: rgb(255, 255, 255);

  @media (max-width: 1024px) {
    font-size: 32px;
  }
}

h2 {
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  letter-spacing: 0.96px;
  text-transform: capitalize;
  color: rgb(255, 255, 255);

  @media (max-width: 1024px) {
    font-size: 28px;
  }
}

h3 {
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  color: rgb(255, 255, 255);

  @media (max-width: 1024px) {
    font-size: 22px;
  }
}

h4 {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  color: rgb(255, 255, 255);

  @media (max-width: 1024px) {
    font-size: 18px;
  }
}

h5 {
  font-size: 18px;
  font-weight: 700;
  line-height: 1.5;
  color: rgb(255, 255, 255);

  @media (max-width: 1024px) {
    font-size: 16px;
  }
}

h6 {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.5;
  color: rgb(255, 255, 255);

  @media (max-width: 1024px) {
    font-size: 14px;
  }
}

.title {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}

.body-bold {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.32px;

  @media (max-width: 1024px) {
    font-weight: 600;
  }
}

.body {
  white-space: pre-line;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  letter-spacing: 0.64px;
}

.body2 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  @media (max-width: 1024px) {
    font-size: 16px;
  }
}

.body-bold {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0.32px;
}

.small {
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;

  @media (max-width: 1024px) {
    font-size: 14px;
  }
}

.button-m {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0.32px;
  text-transform: capitalize;
}

.button-l {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: 0.96px;
  text-transform: capitalize;
}

.button-header {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  /* 16px */
  letter-spacing: 0.64px;
  text-transform: uppercase;
}

.button-m-line {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  /* 16px */
  letter-spacing: 0.32px;
  text-decoration-line: underline;
  text-transform: uppercase;
}

button {
  -webkit-appearance: none;
  appearance: none;
}

input:not([type="checkbox"]):not([type="radio"]) {
  display: flex;
  height: 44px;
  padding: 8px 16px;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  align-self: stretch;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
  border: 1px solid transparent;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  letter-spacing: 0.16px;
}

input:not([type="checkbox"]):not([type="radio"]):focus {
  outline: none !important;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  border: 1px solid var(--dark-text-light-gray, #c4c4c4);
  background: var(--dark-background-light, #fff);
}

input::placeholder {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  letter-spacing: 0.16px;
}

textarea {
  display: flex;
  height: 85px;
  min-height: 44px;
  padding: 12px 6px 6px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: 8px;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
}

input[type="checkbox"] {
  align-self: flex-start;
  margin-top: 6px;
}

input[type="radio"] {
  margin: 0px 3px 0px 5px;
}

.kg-select {
  border-radius: 8px;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
  background: var(--dark-text-text-contrast, #fff);
}

.kg-select input:not([type="checkbox"]):not([type="radio"]) {
  display: flex;
  height: 44px;
  padding: 8px 16px;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  align-self: stretch;
  width: 100%;
  box-shadow: none;
  border: none;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  letter-spacing: 0.16px;
}

.kg-wrapper {
  padding-left: 8vw;
  padding-right: 8vw;

  @media (max-width: 1024px) {
    padding-left: 4vw;
    padding-right: 4vw;
  }
}

.kg-text-shadow {
  text-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
}

.MuiDialog-paper {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  border-radius: 16px;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
}


#vertical-line {
  animation: vertical-line_c_o 4s linear infinite normal forwards
}

@keyframes vertical-line_c_o {
  0% {
    opacity: 0
  }

  5% {
    opacity: 1
  }

  90% {
    opacity: 1
  }

  100% {
    opacity: 0
  }
}

#ring-1 {
  animation: ring-opacity1 4s linear infinite normal forwards
}

#ring-2 {
  animation: ring-opacity2 4s linear infinite normal forwards
}

#ring-3 {
  animation: ring-opacity3 4s linear infinite normal forwards
}

#ring-4 {
  animation: ring-opacity4 4s linear infinite normal forwards
}

@keyframes ring-opacity1 {
  0% {
    opacity: 0
  }

  50% {
    opacity: 1
  }

  100% {
    opacity: 0
  }
}

@keyframes ring-opacity4 {
  0% {
    opacity: 0
  }


  20% {
    opacity: 0
  }

  40% {
    opacity: 1
  }

  60% {
    opacity: 1
  }

  100% {
    opacity: 0
  }
}

@keyframes ring-opacity3 {
  0% {
    opacity: 0
  }

  30% {
    opacity: 0
  }

  60% {
    opacity: 1
  }

  70% {
    opacity: 1
  }

  90% {
    opacity: 0
  }
}

@keyframes ring-opacity2 {
  0% {
    opacity: 0
  }

  35% {
    opacity: 0
  }

  65% {
    opacity: 1
  }

  75% {
    opacity: 1
  }

  85% {
    opacity: 0
  }
}




#center-circle_ts {
  animation: center-circle_ts__ts 4s linear infinite normal forwards
}

@keyframes center-circle_ts__ts {
  0% {
    transform: scale(0, 0)
  }

  21.1% {
    transform: scale(1, 1)
  }


  40% {
    transform: scale(1.0656, 1.0656)
  }


  100% {
    transform: scale(1.0656, 1.0656)
  }
}

#center-circle {
  animation: center-circle_c_o 4s linear infinite normal forwards
}

@keyframes center-circle_c_o {
  0% {
    opacity: 0
  }

  22% {
    opacity: 1
  }

  77.1% {
    opacity: 1
  }

  100% {
    opacity: 0
  }
}
